import { useCallback, useMemo, useState } from "react";

import { DashboardRoutes } from "@simplyk/common";

import { CheckListItemCTAProps } from "../helper";

export enum ActivationStepId {
  SimplifiedOnboarding = "SimplifiedOnboarding",
  CreateFirstForm = "CreateFirstForm",
  LinkBank = "LinkBank",
  CollectFirstDonation = "CollectFirstDonation",
  WatchDemo = "WatchDemo",
  MonitorContactInfo = "MonitorContactInfo",
  CentralizeYourContacts = "CentralizeYourContacts",
  CommunicateWithContacts = "CommunicateWithContacts",
  MonitorPaymentInfo = "MonitorPaymentInfo",
  ManageOfflinePayments = "ManageOfflinePayments",
  InviteYourTeammates = "InviteYourTeammates",
  FinalSuccess = "FinalSuccess",
  VerifyEmail = "VerifyEmail",
  VerifyEmailSent = "VerifyEmailSent",
}

export enum EngagementStep {
  StartRaisingMoney = "startRaisingMoney",
  HowToEngageContacts = "howToEngageContacts",
  HowToOptimizeReporting = "howToOptimizeReporting",
}

export type ActivationStepsProps = {
  itemId: ActivationStepId;
  itemName: string;
  itemDescriptionTitle: string;
  itemDescription: string;
  illustrationUrl: string | undefined;
  onclick: () => void;
  cta1Properties: CheckListItemCTAProps;
  cta2Properties?: CheckListItemCTAProps;
  ctaMenuProperties?: CheckListItemCTAProps;
  checked: boolean;
  disabled: boolean;
  removeStartIcon?: boolean;
  hideOnPage?: DashboardRoutes[];
};

export const useActivationCard = ({
  steps,
  selectedStepId,
}: {
  steps: ActivationStepsProps[];
  selectedStepId: ActivationStepId | undefined;
}) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const handleOnExpand = useCallback(() => {
    setIsExpanded((prev) => !prev);
  }, []);

  const stepTotalNumber = useMemo(() => {
    return steps.filter((step) => step.itemId !== ActivationStepId.WatchDemo).length;
  }, [steps]);

  const stepCheckedNumber = useMemo(() => {
    return steps.filter((step) => step.checked).length;
  }, [steps]);

  const nextStep = useMemo(() => {
    return steps.find((step) => !step.checked);
  }, [steps]);

  const selectedStep = useMemo(() => {
    const selectedStep = steps.find((step) => step.itemId === selectedStepId);
    return selectedStep || nextStep;
  }, [nextStep, selectedStepId, steps]);

  return { isExpanded, handleOnExpand, stepTotalNumber, stepCheckedNumber, nextStep, selectedStep };
};
