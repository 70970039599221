import { createContext, FunctionComponent, PropsWithChildren, useState } from "react";

interface PageLoadedProviderProps {
  isLoading: boolean;
  disableLoading?: boolean;
}

export const PageLoadingContext = createContext<{
  isLoading: boolean;
  setApplicationInitialStatusLoading: (applicationInitialStatusLoading: boolean) => void;
  setRedirectionLoading: (redirectionLoading: boolean) => void;
  setGetCurrentUserLoading: (applicationInitialStatusLoading: boolean) => void;
}>({
  setApplicationInitialStatusLoading: () => null,
  setRedirectionLoading: () => null,
  setGetCurrentUserLoading: () => null,
  isLoading: true,
});

export const PageLoadingProvider: FunctionComponent<PropsWithChildren<PageLoadedProviderProps>> = ({
  children,
  disableLoading,
  isLoading: isLoadingInitial,
}) => {
  const [applicationInitialStatusLoading, setApplicationInitialStatusLoading] = useState(true);
  const [redirectionLoading, setRedirectionLoading] = useState(true);
  const [getCurrentUserLoading, setGetCurrentUserLoading] = useState(true);

  if (isLoadingInitial) {
    return null;
  }

  return (
    <PageLoadingContext.Provider
      value={{
        setApplicationInitialStatusLoading,
        setRedirectionLoading,
        setGetCurrentUserLoading,
        isLoading: (applicationInitialStatusLoading || redirectionLoading || getCurrentUserLoading) && !disableLoading,
      }}
    >
      {children}
    </PageLoadingContext.Provider>
  );
};
