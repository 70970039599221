import { AvailablePaymentCurrency } from "../enums";
import { OrganizationCountry } from "../types/countryRegion";
export const getTipAmountFromPercentage = (amount, tipValue) => {
    return amount * (tipValue / 100);
};
export const getDollarValue = (value) => {
    return parseFloat((value / 100).toFixed(2));
};
export const getStringDollarValue = (value) => {
    const dollarValue = getDollarValue(value);
    if (value % 10 === 0 && value % 100 !== 0) {
        return dollarValue.toFixed(2);
    }
    else {
        return dollarValue.toString();
    }
};
export const getCentsValue = (value) => {
    const twoDecimalValue = Math.round(value * 100).toFixed(2);
    return Number(twoDecimalValue);
};
export const getFeesFromCentValue = (amount) => {
    return (amount * 2.9) / 100 + 30;
};
export const getCurrencyFromCountry = (country) => {
    if (country === OrganizationCountry.Canada) {
        return AvailablePaymentCurrency.Cad;
    }
    if (country === OrganizationCountry.UnitedStates) {
        return AvailablePaymentCurrency.Usd;
    }
    return AvailablePaymentCurrency.Cad;
};
export const formatCurrency = (locale, currency) => {
    const options = {
        style: "currency",
        currency,
        currencyDisplay: "symbol",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0,
    };
    return Intl.NumberFormat(locale, options).format(0).replace(/\d/g, "").trim();
};
export const formatCurrencyAmount = (value, locale, currency, options) => {
    const defaultOptions = {
        style: "currency",
        currency,
        currencyDisplay: "symbol",
    };
    return Intl.NumberFormat(locale, options || defaultOptions).format(value);
};
export const formatCurrencyAmountWithoutCents = (value, locale, currency) => {
    const options = {
        style: "currency",
        currency,
        currencyDisplay: "symbol",
        minimumFractionDigits: 0,
        maximumFractionDigits: 0, // No cents
    };
    return Intl.NumberFormat(locale, options).format(value);
};
export const formatNarrowCurrencyAmount = (value, options) => {
    const { locale, currency, withCents = true, ...restOptions } = options || {};
    const o = {
        style: "currency",
        currency,
        currencyDisplay: "symbol",
        minimumFractionDigits: withCents ? undefined : 0,
        maximumFractionDigits: withCents ? undefined : 0,
        ...restOptions,
    };
    return Intl.NumberFormat(locale, o).format(value);
};
export const formatSmartNarrowCurrencyFromCents = (amountInCents, options) => {
    const isWholeDollar = amountInCents % 100 === 0;
    return formatNarrowCurrencyAmount(getDollarValue(amountInCents), {
        ...options,
        withCents: !isWholeDollar,
    });
};
export const formatCurrencyAmountByCountry = (value, locale, country, options) => {
    return formatCurrencyAmount(value, locale, getCurrencyFromCountry(country), options);
};
export const countDecimals = (value) => {
    if (value % 1 !== 0) {
        return value.toString().split(".")[1].length;
    }
    return 0;
};
export var CurrencyCode;
(function (CurrencyCode) {
    CurrencyCode["AED"] = "AED";
    CurrencyCode["AFN"] = "AFN";
    CurrencyCode["ALL"] = "ALL";
    CurrencyCode["AMD"] = "AMD";
    CurrencyCode["ANG"] = "ANG";
    CurrencyCode["AOA"] = "AOA";
    CurrencyCode["ARS"] = "ARS";
    CurrencyCode["AUD"] = "AUD";
    CurrencyCode["AWG"] = "AWG";
    CurrencyCode["AZN"] = "AZN";
    CurrencyCode["BAM"] = "BAM";
    CurrencyCode["BBD"] = "BBD";
    CurrencyCode["BDT"] = "BDT";
    CurrencyCode["BGN"] = "BGN";
    CurrencyCode["BHD"] = "BHD";
    CurrencyCode["BIF"] = "BIF";
    CurrencyCode["BMD"] = "BMD";
    CurrencyCode["BND"] = "BND";
    CurrencyCode["BOB"] = "BOB";
    CurrencyCode["BRL"] = "BRL";
    CurrencyCode["BSD"] = "BSD";
    CurrencyCode["BTN"] = "BTN";
    CurrencyCode["BWP"] = "BWP";
    CurrencyCode["BYN"] = "BYN";
    CurrencyCode["BZD"] = "BZD";
    CurrencyCode["CAD"] = "CAD";
    CurrencyCode["CDF"] = "CDF";
    CurrencyCode["CHF"] = "CHF";
    CurrencyCode["CLP"] = "CLP";
    CurrencyCode["CNY"] = "CNY";
    CurrencyCode["COP"] = "COP";
    CurrencyCode["CRC"] = "CRC";
    CurrencyCode["CUC"] = "CUC";
    CurrencyCode["CUP"] = "CUP";
    CurrencyCode["CVE"] = "CVE";
    CurrencyCode["CZK"] = "CZK";
    CurrencyCode["DJF"] = "DJF";
    CurrencyCode["DKK"] = "DKK";
    CurrencyCode["DOP"] = "DOP";
    CurrencyCode["DZD"] = "DZD";
    CurrencyCode["EGP"] = "EGP";
    CurrencyCode["ERN"] = "ERN";
    CurrencyCode["ETB"] = "ETB";
    CurrencyCode["EUR"] = "EUR";
    CurrencyCode["FJD"] = "FJD";
    CurrencyCode["FKP"] = "FKP";
    CurrencyCode["FOK"] = "FOK";
    CurrencyCode["GBP"] = "GBP";
    CurrencyCode["GEL"] = "GEL";
    CurrencyCode["GGP"] = "GGP";
    CurrencyCode["GHS"] = "GHS";
    CurrencyCode["GIP"] = "GIP";
    CurrencyCode["GMD"] = "GMD";
    CurrencyCode["GNF"] = "GNF";
    CurrencyCode["GTQ"] = "GTQ";
    CurrencyCode["GYD"] = "GYD";
    CurrencyCode["HKD"] = "HKD";
    CurrencyCode["HNL"] = "HNL";
    CurrencyCode["HRK"] = "HRK";
    CurrencyCode["HTG"] = "HTG";
    CurrencyCode["HUF"] = "HUF";
    CurrencyCode["IDR"] = "IDR";
    CurrencyCode["ILS"] = "ILS";
    CurrencyCode["IMP"] = "IMP";
    CurrencyCode["INR"] = "INR";
    CurrencyCode["IQD"] = "IQD";
    CurrencyCode["IRR"] = "IRR";
    CurrencyCode["ISK"] = "ISK";
    CurrencyCode["JEP"] = "JEP";
    CurrencyCode["JMD"] = "JMD";
    CurrencyCode["JOD"] = "JOD";
    CurrencyCode["JPY"] = "JPY";
    CurrencyCode["KES"] = "KES";
    CurrencyCode["KGS"] = "KGS";
    CurrencyCode["KHR"] = "KHR";
    CurrencyCode["KID"] = "KID";
    CurrencyCode["KMF"] = "KMF";
    CurrencyCode["KRW"] = "KRW";
    CurrencyCode["KWD"] = "KWD";
    CurrencyCode["KYD"] = "KYD";
    CurrencyCode["KZT"] = "KZT";
    CurrencyCode["LAK"] = "LAK";
    CurrencyCode["LBP"] = "LBP";
    CurrencyCode["LKR"] = "LKR";
    CurrencyCode["LRD"] = "LRD";
    CurrencyCode["LSL"] = "LSL";
    CurrencyCode["LYD"] = "LYD";
    CurrencyCode["MAD"] = "MAD";
    CurrencyCode["MDL"] = "MDL";
    CurrencyCode["MGA"] = "MGA";
    CurrencyCode["MKD"] = "MKD";
    CurrencyCode["MMK"] = "MMK";
    CurrencyCode["MNT"] = "MNT";
    CurrencyCode["MOP"] = "MOP";
    CurrencyCode["MRU"] = "MRU";
    CurrencyCode["MUR"] = "MUR";
    CurrencyCode["MVR"] = "MVR";
    CurrencyCode["MWK"] = "MWK";
    CurrencyCode["MXN"] = "MXN";
    CurrencyCode["MYR"] = "MYR";
    CurrencyCode["MZN"] = "MZN";
    CurrencyCode["NAD"] = "NAD";
    CurrencyCode["NGN"] = "NGN";
    CurrencyCode["NIO"] = "NIO";
    CurrencyCode["NOK"] = "NOK";
    CurrencyCode["NPR"] = "NPR";
    CurrencyCode["NZD"] = "NZD";
    CurrencyCode["OMR"] = "OMR";
    CurrencyCode["PAB"] = "PAB";
    CurrencyCode["PEN"] = "PEN";
    CurrencyCode["PGK"] = "PGK";
    CurrencyCode["PHP"] = "PHP";
    CurrencyCode["PKR"] = "PKR";
    CurrencyCode["PLN"] = "PLN";
    CurrencyCode["PYG"] = "PYG";
    CurrencyCode["QAR"] = "QAR";
    CurrencyCode["RON"] = "RON";
    CurrencyCode["RSD"] = "RSD";
    CurrencyCode["RUB"] = "RUB";
    CurrencyCode["RWF"] = "RWF";
    CurrencyCode["SAR"] = "SAR";
    CurrencyCode["SBD"] = "SBD";
    CurrencyCode["SCR"] = "SCR";
    CurrencyCode["SDG"] = "SDG";
    CurrencyCode["SEK"] = "SEK";
    CurrencyCode["SGD"] = "SGD";
    CurrencyCode["SHP"] = "SHP";
    CurrencyCode["SLL"] = "SLL";
    CurrencyCode["SOS"] = "SOS";
    CurrencyCode["SRD"] = "SRD";
    CurrencyCode["SSP"] = "SSP";
    CurrencyCode["STN"] = "STN";
    CurrencyCode["SVC"] = "SVC";
    CurrencyCode["SYP"] = "SYP";
    CurrencyCode["SZL"] = "SZL";
    CurrencyCode["THB"] = "THB";
    CurrencyCode["TJS"] = "TJS";
    CurrencyCode["TMT"] = "TMT";
    CurrencyCode["TND"] = "TND";
    CurrencyCode["TOP"] = "TOP";
    CurrencyCode["TRY"] = "TRY";
    CurrencyCode["TTD"] = "TTD";
    CurrencyCode["TVD"] = "TVD";
    CurrencyCode["TWD"] = "TWD";
    CurrencyCode["TZS"] = "TZS";
    CurrencyCode["UAH"] = "UAH";
    CurrencyCode["UGX"] = "UGX";
    CurrencyCode["USD"] = "USD";
    CurrencyCode["UYU"] = "UYU";
    CurrencyCode["UZS"] = "UZS";
    CurrencyCode["VES"] = "VES";
    CurrencyCode["VND"] = "VND";
    CurrencyCode["VUV"] = "VUV";
    CurrencyCode["WST"] = "WST";
    CurrencyCode["XAF"] = "XAF";
    CurrencyCode["XCD"] = "XCD";
    CurrencyCode["XDR"] = "XDR";
    CurrencyCode["XOF"] = "XOF";
    CurrencyCode["XPF"] = "XPF";
    CurrencyCode["YER"] = "YER";
    CurrencyCode["ZAR"] = "ZAR";
    CurrencyCode["ZMW"] = "ZMW";
    CurrencyCode["ZWL"] = "ZWL";
})(CurrencyCode || (CurrencyCode = {}));
