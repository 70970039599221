import * as Types from '../../gql-types';

import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
const defaultOptions = {} as const;
export type UpdateHubspotContactPropertyMutationVariables = Types.Exact<{
  payload: Types.HubspotProperty;
}>;


export type UpdateHubspotContactPropertyMutation = { __typename?: 'Mutation', updateHubspotContactProperty: { __typename?: 'BooleanYLbHQ40i', object?: boolean | null } };

export type FetchAndSetSkipAiChatQueryVariables = Types.Exact<{ [key: string]: never; }>;


export type FetchAndSetSkipAiChatQuery = { __typename?: 'Query', fetchAndSetSkipAIChat: { __typename?: 'BooleanqiFSy8kg', object?: boolean | null } };


export const UpdateHubspotContactPropertyDocument = gql`
    mutation updateHubspotContactProperty($payload: HubspotProperty!) {
  updateHubspotContactProperty(payload: $payload) {
    object
  }
}
    `;
export type UpdateHubspotContactPropertyMutationFn = Apollo.MutationFunction<UpdateHubspotContactPropertyMutation, UpdateHubspotContactPropertyMutationVariables>;

/**
 * __useUpdateHubspotContactPropertyMutation__
 *
 * To run a mutation, you first call `useUpdateHubspotContactPropertyMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateHubspotContactPropertyMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateHubspotContactPropertyMutation, { data, loading, error }] = useUpdateHubspotContactPropertyMutation({
 *   variables: {
 *      payload: // value for 'payload'
 *   },
 * });
 */
export function useUpdateHubspotContactPropertyMutation(baseOptions?: Apollo.MutationHookOptions<UpdateHubspotContactPropertyMutation, UpdateHubspotContactPropertyMutationVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useMutation<UpdateHubspotContactPropertyMutation, UpdateHubspotContactPropertyMutationVariables>(UpdateHubspotContactPropertyDocument, options);
      }
export type UpdateHubspotContactPropertyMutationHookResult = ReturnType<typeof useUpdateHubspotContactPropertyMutation>;
export type UpdateHubspotContactPropertyMutationResult = Apollo.MutationResult<UpdateHubspotContactPropertyMutation>;
export type UpdateHubspotContactPropertyMutationOptions = Apollo.BaseMutationOptions<UpdateHubspotContactPropertyMutation, UpdateHubspotContactPropertyMutationVariables>;
export const FetchAndSetSkipAiChatDocument = gql`
    query fetchAndSetSkipAIChat {
  fetchAndSetSkipAIChat {
    object
  }
}
    `;

/**
 * __useFetchAndSetSkipAiChatQuery__
 *
 * To run a query within a React component, call `useFetchAndSetSkipAiChatQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetchAndSetSkipAiChatQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetchAndSetSkipAiChatQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetchAndSetSkipAiChatQuery(baseOptions?: Apollo.QueryHookOptions<FetchAndSetSkipAiChatQuery, FetchAndSetSkipAiChatQueryVariables>) {
        const options = {...defaultOptions, ...baseOptions}
        return Apollo.useQuery<FetchAndSetSkipAiChatQuery, FetchAndSetSkipAiChatQueryVariables>(FetchAndSetSkipAiChatDocument, options);
      }
export function useFetchAndSetSkipAiChatLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<FetchAndSetSkipAiChatQuery, FetchAndSetSkipAiChatQueryVariables>) {
          const options = {...defaultOptions, ...baseOptions}
          return Apollo.useLazyQuery<FetchAndSetSkipAiChatQuery, FetchAndSetSkipAiChatQueryVariables>(FetchAndSetSkipAiChatDocument, options);
        }
export type FetchAndSetSkipAiChatQueryHookResult = ReturnType<typeof useFetchAndSetSkipAiChatQuery>;
export type FetchAndSetSkipAiChatLazyQueryHookResult = ReturnType<typeof useFetchAndSetSkipAiChatLazyQuery>;
export type FetchAndSetSkipAiChatQueryResult = Apollo.QueryResult<FetchAndSetSkipAiChatQuery, FetchAndSetSkipAiChatQueryVariables>;