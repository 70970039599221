import { getAllTimezones, getTimezone } from "countries-and-timezones";
import { DateTime } from "luxon";
import { CadIsoLocale, Locales } from "../enums";
import { OrganizationCountry } from "../types/countryRegion";
const YYYY_MM_DD_FORMAT = "yyyy-MM-dd";
export const browserDefaultTimezone = DateTime.local().zoneName || "America/Montreal";
// arbitrary values chosen to handle most of the cases for our current time
export const minDate = new Date(1900, 1, 1, 0, 0, 0, 0);
export const maxDate = new Date(2100, 1, 1, 0, 0, 0, 0);
const invalidDateTimeString = "Invalid DateTime";
export const getTimezoneObject = getTimezone;
export const getTimezoneStrFromTimezone = (timezoneName) => {
    const timezone = getTimezone(timezoneName);
    if (!timezone) {
        return "";
    }
    if (timezone.utcOffsetStr === timezone.dstOffsetStr) {
        return `(GMT${timezone.utcOffsetStr}) ${timezoneName}`;
    }
    return `(GMT${timezone.utcOffsetStr} / GMT${timezone.dstOffsetStr}) ${timezoneName}`;
};
export const luxonValidTimezones = Array.from(new Set(Object.keys(getAllTimezones())
    .filter((tz) => tz.includes("/") && DateTime.local().setZone(tz).isValid)
    .map((timezone) => ({ timezone, timezoneStr: getTimezoneStrFromTimezone(timezone) })))).sort((a, b) => (a.timezoneStr < b.timezoneStr ? -1 : 1));
export const getDateTimeAtTimezone = (date, timezone) => {
    const zone = getTimezone(timezone);
    const defaultZone = getTimezone(browserDefaultTimezone);
    if (!zone || !defaultZone) {
        return null;
    }
    const utcOffset = zone.utcOffset - defaultZone.utcOffset;
    return new Date(new Date(date).getTime() - utcOffset * 60 * 1000);
};
export const getUtcDateTime = (date, timezone) => {
    const zone = getTimezone(timezone || browserDefaultTimezone);
    const defaultZone = getTimezone(browserDefaultTimezone);
    if (!zone || !defaultZone) {
        return null;
    }
    const utcOffset = zone.utcOffset - defaultZone.utcOffset;
    return new Date(new Date(date).getTime() + utcOffset * 60 * 1000);
};
export const getShortUtc = (date, locale) => {
    const formatter = new Intl.DateTimeFormat(locale, {
        hour: "2-digit",
        minute: "2-digit",
        timeZoneName: "short",
    });
    if (!formatter.formatToParts) {
        return "";
    }
    const formattedTime = formatter.formatToParts(new Date(date));
    return formattedTime.find((formattedPart) => formattedPart.type === "timeZoneName")?.value || "";
};
export const getDateTime = (date, options) => {
    if (typeof date === "number") {
        return DateTime.fromMillis(date, options);
    }
    // Handle string timestamps (milliseconds)
    if (typeof date === "string" && /^\d+$/.test(date)) {
        return DateTime.fromMillis(parseInt(date, 10), options);
    }
    return DateTime.fromJSDate(new Date(date), options);
};
export const getDatePlusNSeconds = (date, seconds) => getDateTime(date).plus({ seconds }).toJSDate();
export const getDateMinusNSeconds = (date, seconds) => getDateTime(date).minus({ seconds }).toJSDate();
export const getDatePlusNMinutes = (date, minutes) => getDateTime(date).plus({ minutes }).toJSDate();
export const getDateMinusNMinutes = (date, minutes) => getDateTime(date).minus({ minutes }).toJSDate();
export const getDatePlusNHours = (date, hours) => getDateTime(date).plus({ hours }).toJSDate();
export const getDateMinusNHours = (date, hours) => getDateTime(date).minus({ hours }).toJSDate();
export const getDatePlusNDays = (date, days) => getDateTime(date).plus({ days }).toJSDate();
export const getDateMinusNDays = (date, days) => getDateTime(date).minus({ days }).toJSDate();
export const getDatePlusNWeeks = (date, weeks) => getDateTime(date).plus({ weeks }).toJSDate();
export const getDateMinusNWeeks = (date, weeks) => getDateTime(date).minus({ weeks }).toJSDate();
export const getDatePlusNMonths = (date, months) => getDateTime(date).plus({ months }).toJSDate();
export const getDateMinusNMonths = (date, months) => getDateTime(date).minus({ months }).toJSDate();
export const getDatePlusNYears = (date, years) => getDateTime(date).plus({ years }).toJSDate();
export const getDateMinusNYears = (date, years) => getDateTime(date).minus({ years }).toJSDate();
export const getDateLastYear = (date) => new Date(new Date(date).setFullYear(new Date().getFullYear() - 1));
export const getDateThisYear = (date) => new Date(new Date(date).setFullYear(new Date().getFullYear()));
export const getDateNextYear = (date) => new Date(new Date(date).setFullYear(new Date().getFullYear() + 1));
export const getDateEndOfDay = (date) => getDateTime(date).endOf("day").toJSDate();
export const getDateMidDay = (date) => new Date(new Date(date).setHours(12, 0, 0, 0));
export const getDateStartOfDay = (date) => getDateTime(date).startOf("day").toJSDate();
export const getDateStartOfMonth = (date) => getDateTime(date).startOf("month").toJSDate();
export const getDateEndOfMonth = (date) => getDateTime(date).endOf("month").toJSDate();
export const getDateStartOfYear = (date) => getDateTime(date).startOf("year").toJSDate();
export const getDateEndOfYear = (date) => getDateTime(date).endOf("year").toJSDate();
export const isDayAndMonthInTheFuture = (date) => {
    const now = new Date();
    const currentDay = now.getDate();
    const currentMonth = now.getMonth();
    const dayOfDate = new Date(date).getDate();
    const monthOfDate = new Date(date).getMonth();
    return monthOfDate > currentMonth || (monthOfDate === currentMonth && dayOfDate > currentDay);
};
export const isDateStrictlyInThePast = (date) => {
    const now = getDateTime(new Date());
    return getDateTime(date).startOf("day") < now.startOf("day");
};
export const isDateStrictlyInTheFuture = (date) => {
    const now = getDateTime(new Date());
    return getDateTime(date).startOf("day") > now.startOf("day");
};
export const isDateInTheFuture = (date) => !isDateStrictlyInThePast(date);
export const isDateAfterOneYear = (date) => {
    const nowPlusOneYear = getDateTime(getDatePlusNYears(new Date(), 1));
    return getDateTime(date).startOf("day") > nowPlusOneYear.startOf("day");
};
export const isDateToday = (date) => isDateSameDay(date, new Date());
export const isDateSameDay = (date1, date2) => {
    const dateTime1 = getDateTime(date1);
    const dateTime2 = getDateTime(date2);
    return (dateTime1.hasSame(dateTime2, "year") && dateTime1.hasSame(dateTime2, "month") && dateTime1.hasSame(dateTime2, "day"));
};
export const isDateSameDayDependingOnTimezone = (startUtc, endUtc, isoLocale, eventTimezone) => {
    if (!startUtc || !endUtc) {
        return false;
    }
    const options = {
        timeZone: eventTimezone,
        year: "numeric",
        month: "numeric",
        day: "numeric",
    };
    const formatter = new Intl.DateTimeFormat(isoLocale, options);
    const startDateString = formatter.format(startUtc);
    const endDateString = formatter.format(endUtc);
    return startDateString === endDateString;
};
export const getMonthDiff = (date1, date2) => {
    const dateTime1 = getDateTime(date1);
    const dateTime2 = getDateTime(date2);
    return dateTime2.diff(dateTime1, "months").toObject().months || 0;
};
export const getNextOccurrence = (date) => isDayAndMonthInTheFuture(date) ? getDateThisYear(date) : getDateNextYear(date);
export const getNextOccurrenceWithThreeMonthGracePeriod = (date) => {
    const dateMidDay = getDateMidDay(date);
    const nextOccurrence = getNextOccurrence(dateMidDay);
    const monthDiff = getMonthDiff(getDateMidDay(new Date()), nextOccurrence);
    return monthDiff > 3 ? nextOccurrence : getDatePlusNYears(nextOccurrence, 1);
};
export const getLastOccurrence = (date) => isDayAndMonthInTheFuture(date) ? getDateLastYear(date) : getDateThisYear(date);
export const getDateTimestampInMilliseconds = (date) => new Date(date).getTime();
export const getDateTimestampInSeconds = (date) => Math.floor(getDateTimestampInMilliseconds(new Date(date)) / 1000);
export const EndTicketEndTimePreset = {
    hour: "numeric",
    minute: "2-digit",
    timeZoneName: "short",
    hour12: true,
};
export const StartTicketEndTimePreset = {
    hour: "numeric",
    minute: "2-digit",
    hour12: true,
};
export const formatDateTimeToLocaleString = (date, locale, option, timeZone) => {
    if (option && locale === CadIsoLocale.FR) {
        // specific 24 time based for french
        return timeZone
            ? getDateTime(date)
                .setLocale(locale.toLowerCase())
                .setZone(timeZone)
                .toLocaleString(option ? { ...option, hour12: false } : {})
            : getDateTime(date)
                .setLocale(locale.toLowerCase())
                .toLocaleString(option ? { ...option, hour12: false } : {});
    }
    return timeZone
        ? getDateTime(date).setLocale(locale.toLowerCase()).setZone(timeZone).toLocaleString(option)
        : getDateTime(date).setLocale(locale.toLowerCase()).toLocaleString(option);
};
export const formatDateTimeToFormat = (date, locale, format, timeZone) => timeZone
    ? getDateTime(date).setLocale(locale.toLowerCase()).setZone(timeZone).toFormat(format)
    : getDateTime(date).setLocale(locale.toLowerCase()).toFormat(format);
export const formatDateToIsoString = (date) => getDateTime(date).toISODate() || invalidDateTimeString;
export const formatDateToIso = (date, options) => getDateTime(date).toISO(options) || invalidDateTimeString;
export const formatDateToRelative = (date, locale) => getDateTime(date).setLocale(locale.toLowerCase()).toRelative();
/**
 * @param date
 * @param locale
 * @returns format like 'April 25, 2022'
 */
export const formatDateToFullDate = (date, locale, timeZone) => formatDateTimeToLocaleString(date, locale, DateTime.DATE_FULL, timeZone);
/**
 * @param date
 * @param locale
 * @returns format like 'Apr 25, 2022'
 */
export const formatDateToMedDate = (date, locale, timeZone) => formatDateTimeToLocaleString(date, locale, DateTime.DATE_MED, timeZone);
/**
 * @param date
 * @param locale
 * @returns format like 25/04/2022
 */
export const formatDateToShortDate = (date, locale) => formatDateTimeToLocaleString(date, locale, DateTime.DATE_SHORT);
/**
 * @param date
 * @param locale
 * @returns format like 'April 25, 2022, 9:30 AM EDT'
 */
export const formatDateToFullDateTime = (date, locale) => formatDateTimeToLocaleString(date, locale, DateTime.DATETIME_FULL);
/**
 * @param date
 * @param locale
 * @returns format like 'Apr 25, 2022, 9:30 AM'
 */
export const formatDateToMediumDateTime = (date, locale, timeZone) => formatDateTimeToLocaleString(date, locale, DateTime.DATETIME_MED, timeZone);
/**
 * @param date
 * @param locale
 * @returns format like '25/04/2022, 9:30 AM'
 */
export const formatDateToShortDateTime = (date, locale, timeZone) => formatDateTimeToLocaleString(date, locale, DateTime.DATETIME_SHORT, timeZone);
export const getFormatDateToDayAndMonth = (locale) => {
    switch (locale) {
        case Locales.EN:
            return "LLL d";
        case Locales.FR:
            return "d LLL";
        default:
            return "dd/LL";
    }
};
export const getFormatDateToHours = () => "HH";
export const getFormatDateToMinutes = () => "mm";
export const getFormatDateToTime = () => "HH:mm";
export const Months = {
    FR: [
        "Janvier",
        "Février",
        "Mars",
        "Avril",
        "Mai",
        "Juin",
        "Juillet",
        "Août",
        "Septembre",
        "Octobre",
        "Novembre",
        "Décembre",
    ],
    EN: [
        "January",
        "February",
        "March",
        "April",
        "May",
        "June",
        "July",
        "August",
        "September",
        "October",
        "November",
        "December",
    ],
    ES: [
        "Enero",
        "Febrero",
        "Marzo",
        "Abril",
        "Mayo",
        "Junio",
        "Julio",
        "Agosto",
        "Septiembre",
        "Octubre",
        "Noviembre",
        "Diciembre",
    ],
};
export const formatDateToLastMonth = (date, locale) => {
    return Months[locale][(getDateTime(date).month + 10) % 12];
};
export const setTimeToDate = (date, time) => {
    const robustTime = new Date(time);
    return new Date(new Date(date).setHours(robustTime.getHours(), robustTime.getMinutes(), 0, 0));
};
export const formatDateToTimestampZeroUTCHours = (date) => {
    const date1 = new Date(date);
    date1.setUTCHours(0, 0, 0, 0);
    return Date.parse(date1.toString());
};
export const formatDateToZeroUTCHours = (date) => {
    return new Date(date).setUTCHours(0, 0, 0, 0);
};
export const getMonthListFromDate = (date) => {
    let i = new Date(new Date(date).getFullYear(), new Date(date).getMonth(), 1, 0, 0, 1).getTime();
    const now = Date.now();
    if (i > now) {
        return [];
    }
    const monthList = [];
    while (i < now) {
        monthList.push(new Date(i).setDate(1));
        i = getDatePlusNMonths(new Date(i), 1).getTime();
    }
    return monthList.map((timestamps) => {
        const date = new Date(timestamps);
        return {
            month: date.getMonth(),
            year: date.getFullYear(),
        };
    });
};
export const getYearListFromDate = (date) => {
    if (new Date(date).getTime() >= Date.now()) {
        return [];
    }
    const yearList = [];
    let i = new Date(date).getFullYear();
    const now = new Date().getFullYear();
    while (i <= now) {
        yearList.push(i);
        i++;
    }
    return yearList;
};
export const getJanuaryFirst10UTC = (year) => {
    return new Date(Date.UTC(year, 0, 1, 10, 0, 0));
};
export const getYearBoundaries = (year) => {
    const startDate = new Date(Date.UTC(year, 0, 1));
    const endDate = new Date(Date.UTC(year + 1, 0, 1));
    return [startDate, endDate];
};
export const getMonthBoundaries = (month, year) => {
    const startDate = new Date(year, month, 1);
    const endDate = new Date(year, month + 1, 1);
    return [startDate, endDate];
};
export const getWeekBoundaries = (date) => {
    const startDate = getDateTime(date).startOf("week").toJSDate();
    const endDate = getDateTime(date).endOf("week").toJSDate();
    return [startDate, endDate];
};
export const getDayBoundaries = (date) => {
    const startDate = getDateStartOfDay(date);
    const endDate = getDateEndOfDay(date);
    return [startDate, endDate];
};
export const parseDateByCountry = (date, country) => {
    const parsedDate = country === OrganizationCountry.Canada
        ? DateTime.fromFormat(date, "dd/MM/yyyy")
        : DateTime.fromFormat(date, "MM/dd/yyyy");
    return parsedDate.toJSDate();
};
export const parseUSDate = (date) => {
    return DateTime.fromFormat(date, "MM/dd/yyyy").toJSDate();
};
/**
 *
 * @param timestamp
 * @returns A date string in the format 'YYYY-MM-DD'
 */
export const convertTimestampToDateString = (timestamp) => {
    const luxonDate = DateTime.fromJSDate(timestamp instanceof Date ? timestamp : new Date(timestamp));
    return luxonDate.toFormat(YYYY_MM_DD_FORMAT);
};
/**
 *
 * @param timestamp
 * @returns A date number in the format 'YYYYMMDD'
 */
export const convertTimestampToDateNumber = (timestamp) => {
    const luxonDate = DateTime.fromJSDate(timestamp instanceof Date ? timestamp : new Date(timestamp));
    return Number(luxonDate.toFormat("yyyyMMdd"));
};
export const convertTimestampToCohort = (timestamp) => {
    const luxonDate = DateTime.fromJSDate(timestamp instanceof Date ? timestamp : new Date(timestamp));
    // Monday
    const firstDayOfWeek = luxonDate.startOf("week");
    const firstDayFormatted = firstDayOfWeek.toFormat(YYYY_MM_DD_FORMAT);
    // Sunday
    const lastDayOfWeek = luxonDate.endOf("week");
    const lastDayFormatted = lastDayOfWeek.toFormat(YYYY_MM_DD_FORMAT);
    return `${firstDayFormatted} to ${lastDayFormatted}`;
};
/**
 * @param startDate
 * @param endDate
 * @param locale
 * @returns format like 'October 1 - 24, 2023' if they have the same month and year
 * @returns format like 'Sep 1 - Sep 30, 2023' if they have the same year but a different month
 * @returns format like 'Sep 1, 2022 - Sep 30, 2023' if they have different years
 */
export const formatDateRange = (startDate, endDate, locale) => {
    if (!startDate || !endDate) {
        return "";
    }
    const parsedStartDate = getDateTime(startDate).setLocale(locale.toLowerCase());
    const parsedEndDate = getDateTime(endDate).setLocale(locale.toLowerCase());
    const hasSameMonth = parsedStartDate.month === parsedEndDate.month;
    const hasSameYear = parsedStartDate.year === parsedEndDate.year;
    if (!hasSameYear) {
        return `${parsedStartDate.monthShort} ${parsedStartDate.day}, ${parsedStartDate.year} - ${parsedStartDate.monthShort} ${parsedEndDate.day}, ${parsedEndDate.year}`;
    }
    else if (!hasSameMonth) {
        return `${parsedStartDate.monthShort} ${parsedStartDate.day} - ${parsedEndDate.monthShort} ${parsedEndDate.day}, ${parsedEndDate.year}`;
    }
    else {
        return `${parsedStartDate.monthShort} ${parsedStartDate.day} - ${parsedEndDate.day}, ${parsedEndDate.year}`;
    }
};
export const getUTCDateStartOfDay = (date) => getDateTime(getDateMidDay(date)).setZone("utc").startOf("day").toJSDate();
export const getCurrentDateFullName = (locale) => getDateTime(new Date()).setLocale(locale).toFormat("LLLL");
