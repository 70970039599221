import * as React from "react";
import { FunctionComponent, PropsWithChildren, useContext, useEffect, useMemo } from "react";

import { ApolloQueryResult } from "@apollo/client";
import {
  AvailablePaymentCurrency,
  UserPermissions,
  UserRoles,
  AmplitudeVariantValue as AmplitudeVariantValueCommon,
  getCurrencyFromCountry,
  OrganizationCountry,
} from "@simplyk/common";
import { useRouter } from "next/router";

import { SessionContext } from "../contexts/SessionContext";
import { View } from "../enums/view";
import { ActivationStepId } from "../features/StepperCheckList/ActivationCheckList/useActivationCard";
import { GetCurrentUserFrontendObject, OrganizationObject, AmplitudeVariantValue } from "../gql/gql-types";
import {
  GetFrontendCurrentUserQuery,
  useGetFrontendCurrentUserQuery,
  useUpdateAccessTokenWithTargetedOrganizationMutation,
} from "../gql/queries/generated/authQuery";
import { useFetchAndSetSkipAiChatQuery } from "../gql/queries/generated/hubspotQuery";
import { identifyAmplitudeUser } from "../helpers/amplitude";
import { setSentryUser, setSentryOrganizationTags } from "../helpers/sentry";
import { useAppSelector } from "../hooks/redux";
import { PageLoadingContext } from "../providers/PageLoadingProvider";

interface CurrentUserContextValues {
  currentUser: GetCurrentUserFrontendObject | null | undefined;
  userIsLoading: boolean;
  refetchUser: () => Promise<ApolloQueryResult<GetFrontendCurrentUserQuery>>;
  isOrganization: boolean;
  isOrganizationOwner: boolean;
  isAuthenticated: boolean;
  userRole?: UserRoles | null;
  userPermissions: UserPermissions[];
  organization?: OrganizationObject;
  refetchOrganization: () => Promise<ApolloQueryResult<GetFrontendCurrentUserQuery>>;
  isOrganizationCountryUnitedStates: boolean;
  isOrganizationCountryCanada: boolean;
  isOrganizationOnboarded: boolean;
  hasStripeCustomFlowStarted: boolean;
  isStripeCustomAccountActive: boolean;
  organizationCurrency: AvailablePaymentCurrency;
  connectedAsAdministrator: boolean;
  isMigratedOnStytch: boolean;
  zeffyAdministrator: boolean;
  isSendingEmailForbidden: boolean;
  firstPaymentDate: Date | null;
  organizationHasFinishZeffyOnboarding: boolean;
  displayActivationCheckList: boolean;
  isActivationCheckListActive: boolean;
  isEngagementCheckListActive: boolean;
  engagementTourActive: boolean;
  isEngagementCheckListTerminated: boolean;
}

const defaultValues = {
  currentUser: {} as GetCurrentUserFrontendObject,
  userIsLoading: false,
  refetchUser: async () => ({}) as ApolloQueryResult<GetFrontendCurrentUserQuery>,
  isOrganization: false,
  isOrganizationOwner: false,
  isAuthenticated: false,
  userRole: UserRoles.Owner,
  userPermissions: [],
  organization: undefined,
  refetchOrganization: async () => ({}) as ApolloQueryResult<GetFrontendCurrentUserQuery>,
  isOrganizationCountryUnitedStates: false,
  isOrganizationCountryCanada: true,
  isOrganizationOnboarded: false,
  hasStripeCustomFlowStarted: false,
  isStripeCustomAccountActive: true,
  organizationCurrency: AvailablePaymentCurrency.Usd,
  connectedAsAdministrator: false,
  isMigratedOnStytch: false,
  isSendingEmailForbidden: false,
  firstPaymentDate: null,
  zeffyAdministrator: false,
  organizationHasFinishZeffyOnboarding: false,
  displayActivationCheckList: false,
  isActivationCheckListActive: false,
  isEngagementCheckListActive: false,
  engagementTourActive: false,
  isEngagementCheckListTerminated: false,
};

export const CurrentUserContext = React.createContext<CurrentUserContextValues>(defaultValues);

export const useCurrentUserContext = (): CurrentUserContextValues => {
  return useContext(CurrentUserContext);
};

export const CurrentUserProvider: FunctionComponent<PropsWithChildren> = ({ children }) => {
  const router = useRouter();
  const targetOrganizationId = router.query.targetOrganizationId ? (router.query.targetOrganizationId as string) : null;
  const [updateAccessTokenWithTargetedOrganization] = useUpdateAccessTokenWithTargetedOrganizationMutation();

  const { data: getUser, loading, refetch } = useGetFrontendCurrentUserQuery({ fetchPolicy: "network-only" });
  useFetchAndSetSkipAiChatQuery({
    fetchPolicy: "no-cache",
    skip: loading || !getUser?.getFrontendCurrentUser?.object?.email,
  });

  // If targetOrganizationId is present, update the access token with the targeted organization
  useEffect(() => {
    const updateTargetOrganization = async () => {
      if (targetOrganizationId) {
        await updateAccessTokenWithTargetedOrganization({ variables: { targetOrganizationId } });
        refetch();
      }
    };
    updateTargetOrganization();
  }, [targetOrganizationId, refetch, updateAccessTokenWithTargetedOrganization]);

  const { setIsAdminTheme, setIsFraudulent } = useContext(SessionContext);
  const { setGetCurrentUserLoading } = useContext(PageLoadingContext);

  const view = useAppSelector((state) => state.view.view);
  const currentUser = getUser?.getFrontendCurrentUser?.object as GetCurrentUserFrontendObject | undefined;
  const isAuthenticated = Boolean(currentUser?.id);

  const organization = currentUser?.currentOrganization;
  const isOrganization = Boolean(isAuthenticated && organization);
  const organizationCurrency = getCurrencyFromCountry(organization?.country);
  const isSendingEmailForbidden = Boolean(!organization?.isStripeCustomAccountActive);
  const isOrganizationOwner = useMemo(
    () => Boolean(organization && currentUser?.role === UserRoles.Owner),
    [organization, currentUser?.role]
  );

  const userRole = useMemo(
    () =>
      isOrganizationOwner
        ? UserRoles.Owner
        : isOrganization
          ? UserRoles.Member
          : isAuthenticated
            ? UserRoles.Donor
            : null,
    [isAuthenticated, isOrganization, isOrganizationOwner]
  );

  const userPermissions = useMemo(
    () =>
      currentUser?.organizationGroups.find((organizationGroup) => organization?.id === organizationGroup.organizationId)
        ?.permissions || [],
    [currentUser?.organizationGroups, organization?.id]
  );

  const isOrganizationView = view === View.Organization;

  useEffect(() => {
    const initializeUser = async () => {
      if (currentUser && view) {
        await identifyAmplitudeUser({
          currentUser,
          organization: isOrganizationView ? organization : null,
          currentOrganizationDetails: isOrganizationView ? currentUser.currentOrganizationDetails : null,
        });

        setSentryUser(currentUser);
        if (organization) {
          setSentryOrganizationTags(organization);
        }
      }

      if (organization?.fraudulent) {
        setIsFraudulent(true);
      }
    };
    initializeUser();
  }, [currentUser, isOrganizationView, organization, setIsFraudulent, view]);

  const userIsLoading = loading && !getUser; // !getUser allow to not hide/display all page when refetching
  const organizationHasFinishZeffyOnboarding = Boolean(organization?.hasFinishZeffyOnboarding);

  useEffect(() => {
    setIsAdminTheme(Boolean(currentUser?.connectedAsAdministrator));
  }, [currentUser?.connectedAsAdministrator, setIsAdminTheme]);

  const isActivationCheckListActive = organization?.activationChecklistExperiment === AmplitudeVariantValueCommon.B;

  const isActivationCheckListTerminated = Boolean(organization?.hasDismissActivationPayoutSuccessCard);
  const isEngagementCheckListActive = organization?.engagementChecklistExperiment === AmplitudeVariantValue.B;
  const isEngagementCheckListTerminated = Boolean(
    organization?.engagementCheckListStepChecked?.includes(ActivationStepId.FinalSuccess)
  );

  const isOrganizationOnboarded = Boolean(
    organization?.hasAtLeastOnePayment && organization?.isStripeCustomAccountActive
  );

  const displayActivationCheckList =
    (!isEngagementCheckListActive && !isActivationCheckListActive && !isOrganizationOnboarded) ||
    (isEngagementCheckListActive && !isEngagementCheckListTerminated) ||
    (isActivationCheckListActive && !isActivationCheckListTerminated);

  const engagementTourActive = (isActivationCheckListActive || isEngagementCheckListActive) && isOrganizationView;

  useEffect(() => {
    setGetCurrentUserLoading(userIsLoading);
  }, [setGetCurrentUserLoading, userIsLoading]);

  return (
    <CurrentUserContext.Provider
      value={{
        displayActivationCheckList,
        currentUser: currentUser as GetCurrentUserFrontendObject,
        userIsLoading,
        refetchUser: refetch,
        isOrganization,
        isOrganizationOwner,
        isAuthenticated,
        userRole,
        userPermissions,
        organization: organization || undefined,
        refetchOrganization: refetch,
        isOrganizationCountryUnitedStates: organization?.country === OrganizationCountry.UnitedStates,
        isOrganizationCountryCanada: organization?.country === OrganizationCountry.Canada,
        isOrganizationOnboarded,
        hasStripeCustomFlowStarted: Boolean(organization?.hasStripeCustomFlowStarted),
        isStripeCustomAccountActive: Boolean(organization?.isStripeCustomAccountActive),
        firstPaymentDate: organization?.firstPaymentDate ? new Date(organization?.firstPaymentDate) : null,
        organizationCurrency,
        connectedAsAdministrator: Boolean(currentUser?.connectedAsAdministrator),
        isMigratedOnStytch: Boolean(currentUser?.isMigratedOnStytch),
        zeffyAdministrator: Boolean(currentUser?.zeffyAdministrator),
        isSendingEmailForbidden,
        organizationHasFinishZeffyOnboarding,
        isActivationCheckListActive,
        isEngagementCheckListActive,
        engagementTourActive,
        isEngagementCheckListTerminated,
      }}
    >
      {children}
    </CurrentUserContext.Provider>
  );
};
