import * as amplitude from "@amplitude/analytics-browser";
import { ValidPropertyType } from "@amplitude/analytics-types";
import {
  AMPLITUDE_GROUP_TYPE,
  DONOR_ONGOING_AB_TEST,
  ORGANIZATION_ONGOING_AB_TESTS_KEY,
  getDollarValue,
  convertTimestampToCohort,
  convertTimestampToDateNumber,
  AmplitudeOrganizationProperties,
  AmplitudeUserProperties,
} from "@simplyk/common";
import { DateTime } from "luxon";

import { IdentifyAmplitudeUserProps } from "../constants/amplitude";
import { isProduction } from "../constants/env";

const editUserProperty = (
  property: string,
  value: string | number | boolean | object,
  editUserPropertyCallback: () => void
) => {
  editUserPropertyCallback();
  if (!isProduction) {
    // eslint-disable-next-line no-console
    console.log("Amplitude Identify", { property, value });
  }
};

export const appendUserProperty = (property: string, value: ValidPropertyType) => {
  editUserProperty(property, value, () => {
    const identify = new amplitude.Identify().append(property, typeof value === "boolean" ? String(value) : value);

    amplitude.identify(identify);
  });
};

export const setUserProperty = (property: string, value: ValidPropertyType) => {
  editUserProperty(property, value, () => {
    const identify = new amplitude.Identify().set(property, value);

    amplitude.identify(identify);
  });
};

export const identifyAmplitudeUser = async ({
  currentUser,
  organization,
  currentOrganizationDetails,
}: IdentifyAmplitudeUserProps) => {
  const organizationOngoingAbTestsValues: string[] = [];

  const payload = {
    userId: currentUser.id || "",
    email: currentUser.email || "",
    userSignupDate: convertTimestampToDateNumber(currentUser.createdAtUtc),
    userCohort: convertTimestampToCohort(currentUser.createdAtUtc),
    userIsInternal:
      currentUser.zeffyAdministrator ||
      currentUser.email.includes("@simplyk.com") ||
      currentUser.email.includes("@zeffy.com"),
    // Organization data
    organizationId: organization?.id || "",
    organizationName: organization?.name || "",
    organizationCountry: organization?.country || "",
    organizationRegion: organization?.region || "",
    organizationCreationDate: organization?.createdAtUtc ? convertTimestampToDateNumber(organization.createdAtUtc) : "",
    organizationClosingDate: currentOrganizationDetails?.closingDate
      ? convertTimestampToDateNumber(currentOrganizationDetails.closingDate)
      : null,
    organizationFundsRaised: getDollarValue(currentOrganizationDetails?.fundsRaised || 0),
    organizationNumberContacts: currentOrganizationDetails?.numberContacts || 0,
    organizationNumberPayments: currentOrganizationDetails?.numberPayments || 0,
    organizationNumberForms: currentOrganizationDetails?.numberForms || 0,
    organizationNumberUserAccounts: currentOrganizationDetails?.numberUserAccounts || 0,
    organizationNumberOfReferrals: currentOrganizationDetails?.numberOfReferrals || 0,
    organizationServiceEntry: organization?.onboardedFormCategory,
    organizationOngoingAbTestsValues,
    donorOngoingAbTest: [],
    organizationFirstPaymentDate: organization?.firstPaymentDate
      ? convertTimestampToDateNumber(organization?.firstPaymentDate)
      : 0,
    organizationFourthPaymentDate: organization?.fourthPaymentDate
      ? convertTimestampToDateNumber(
          DateTime.fromFormat(organization?.fourthPaymentDate.toString(), "yyyy-MM-dd").toMillis()
        )
      : 0,
    organizationLastPaymentDate: organization?.lastPaymentDate
      ? convertTimestampToDateNumber(organization?.lastPaymentDate)
      : 0,
    organizationBankSetupDate: organization?.stripeCustomAccountActiveAtUtc
      ? convertTimestampToDateNumber(organization.stripeCustomAccountActiveAtUtc)
      : 0,
    organizationNpoStarterLead: Boolean(organization?.hubspotNpoStarterDealId),
  };

  const organizationProperties: AmplitudeOrganizationProperties = {
    organization_zeffy_id: payload.organizationId,
    organization_name: payload.organizationName,
    organization_country: payload.organizationCountry,
    organization_region: payload.organizationRegion,
    organization_creation_date: payload.organizationCreationDate,
    organization_funds_raised: payload.organizationFundsRaised,
    organization_number_contacts: payload.organizationNumberContacts,
    organization_number_payments: payload.organizationNumberPayments,
    organization_number_forms: payload.organizationNumberForms,
    organization_number_user_accounts: payload.organizationNumberUserAccounts,
    organization_service_entry: payload.organizationServiceEntry || "",
    [ORGANIZATION_ONGOING_AB_TESTS_KEY]: organizationOngoingAbTestsValues,
    organization_first_payment_date: payload.organizationFirstPaymentDate,
    organization_fourth_payment_date: payload.organizationFourthPaymentDate,
    organization_last_payment_date: payload.organizationLastPaymentDate,
    organization_bank_setup_date: payload.organizationBankSetupDate,
    organization_closing_date: payload.organizationClosingDate || "",
    organization_number_of_referrals: payload.organizationNumberOfReferrals,
    organization_npo_starter_lead: payload.organizationNpoStarterLead,
  };

  amplitude.setUserId(payload.userId);

  if (organization) {
    amplitude.setGroup(AMPLITUDE_GROUP_TYPE, organization.id);
    const organizationIdentify = new amplitude.Identify();

    Object.keys(organizationProperties).forEach((key) => {
      organizationIdentify.set(key, organizationProperties[key as keyof typeof organizationProperties]);
    });
    amplitude.groupIdentify(AMPLITUDE_GROUP_TYPE, payload.organizationId, organizationIdentify);
  }

  const userProperties: AmplitudeUserProperties = {
    user_zeffy_id: payload.userId,
    user_email: payload.email,
    user_signup_date: payload.userSignupDate,
    user_cohort: payload.userCohort,
    user_is_internal: payload.userIsInternal,
    [DONOR_ONGOING_AB_TEST]: payload.donorOngoingAbTest,
  };

  const userIdentify = new amplitude.Identify();
  const properties = organization ? { ...organizationProperties, ...userProperties } : userProperties;
  Object.keys(properties).forEach((key) => {
    userIdentify.set(key, properties[key as keyof typeof properties]);
  });
  amplitude.identify(userIdentify);

  amplitude.flush();

  if (!isProduction) {
    // eslint-disable-next-line no-console
    console.log("Amplitude Identify", payload);
  }
};

export const setGroupProperty = ({
  organizationId,
  property,
  value,
}: {
  organizationId: string;
  property: string;
  value: ValidPropertyType;
}) => {
  amplitude.setGroup(AMPLITUDE_GROUP_TYPE, organizationId);
  const organizationIdentify = new amplitude.Identify().set(property, value);
  amplitude.groupIdentify(AMPLITUDE_GROUP_TYPE, organizationId, organizationIdentify);
};
